import React, { useEffect, useState } from "react";
import axios from 'axios';

function NotificationComponent() {
  const [notification, setNotification] = useState(""); // notification 상태 저장

  useEffect(() => {
    //---------------------
    /*
    const fetchData = async() => {
      const res = await fetch('http://localhost:8080/owl/cmi1000e.mics?mode=doGetNotification&value1=OWL&value2=RC-LS');
      const result = res.json();
      return result;
    }	
    fetchData().then( data => {
      console.log ( data ) ;  
      if ( chkDateTime( data.VALUE.notification_start_dt, data.VALUE.notification_close_dt ) ){
        let str = '<div class="box"><div class="in">' + data.VALUE.notification + '</div></div>'        
        setNotification( str ); // notification 상태 업데이트
      }else{
        setNotification('<div class="in">&nbsp;</div>'); // notification 상태 업데이트
      }  
    });
    */     
    fetch("https://rc.owl-checker.com/owl/cmi1000e.mics?mode=doGetNotification&value1=OWL&value2=RC-LS")    
    //fetch("https://owl-sys-beta.owl-developer.net/owl/cmi1000e.mics?mode=doGetNotification&value1=OWL&value2=RC-LS")    
      .then((response) => response.json()) // 응답을 JSON으로 변환 json 
      .then((data) => { 
        if ( chkDateTime( data.VALUE.notification_start_dt, data.VALUE.notification_close_dt ) ){
          let str = '<div class="box"><div class="in">' + data.VALUE.notification + '</div></div>'        
          setNotification( str ); // notification 상태 업데이트
        }else{
          setNotification('<div class="in">&nbsp;</div>'); // notification 상태 업데이트
        }  
      })
      .catch((error) => {
        setNotification('<div class="in">&nbsp;</div>'); // notification 상태 업데이트
        console.log("Error fetching data: ");
        console.log( error);
      })
        
    //---------------------
  }, []); // 빈 배열을 두 번째 인자로 전달하여 컴포넌트가 처음 렌더링될 때만 실행되도록 설정

  return (
    <div class="wrap" dangerouslySetInnerHTML={{ __html: notification }}></div>
  );
}

//-------------------------------------------------------------
// 공지 기간 확인 
//-------------------------------------------------------------
function chkDateTime(frdt, todt ){
  console.log ( "isServerWorking ....") ;
  const today = new Date(); // 현재 날짜를 가져옵니다.
  let year = today.getFullYear(); //년도 구하기
  let month = (today.getMonth()+1);
  let day = today.getDate();
  let hour = today.getHours();
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  const todayTime = year + '' + month + '' + day + '' + hour ;
  console.log ("chkDateTime -> todayTime : " +todayTime,
    ", frdt ->" + frdt +", todt :" + todt) ;
  if ( (parseInt(todayTime) >= parseInt(frdt)) && (parseInt(todayTime) <= parseInt(todt))) {    
    console.log("공지 가능 시간입니다." );
    return true ; 
  }else {
    console.log("공지 불가 합니다. " );
    return false ;
  }    
}

export default NotificationComponent;
