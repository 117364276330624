export default {
  s3: {
    REGION: "ap-northeast-1",
    BUCKET: "owlstart-uploads"
  },
  cognito: {
    REGION: "ap-northeast-1",
    // owl (=rcls) current user pool
    USER_POOL_ID: "ap-northeast-1_AExzj1YD4",                                   // owl-real-pool
    APP_CLIENT_ID: "61cf0rds0adrphq6ceb5vclsg3",                                // owl-real-client
    IDENTITY_POOL_ID: "ap-northeast-1:42f3e3a4-9745-490a-a8fb-8b5d24b555dd"     // owl_federated
  },
  //callback URL
  owl_callback:{
    // 
    starter : "https://rc.owl-checker.com/owl/awsin.mics" ,
    createUser : "https://rc.owl-checker.com/owl/psg6g2IQ80deDw95JckR+WzNUNMqxlIOX5Wed9n+96w=.mics"
    /* local dev
    starter : "http://localhost:8080/owl/awsin.mics",
    createUser : "http://localhost:8080/owl/psg6g2IQ80deDw95JckR+WzNUNMqxlIOX5Wed9n+96w=.mics"
    */
    // #4285, Add 셧다운예정시각, 작업 안내 공지 팝업 여부 yyyyMMddHH.
    ,shutdownDateTime : "2024111623"
    ,jobDateTime : "「１１/１６（土曜日）２３：００～２４：００は "
  }
};
