/*
  초기화면 ( home )
    OWL Start

*/
import React from "react";
import "./Home.css";
import LoaderButton from "../components/LoaderButton";
import NotificationComponent from "../components/NotificationComponent"; // NotificationComponent 임포트

import { Auth } from 'aws-amplify';
import config from '../config';

export default function Home(props) {

  function handleClick(e) {
    e.preventDefault();
    // 생성정보 확인하여 팝업 띄우기.
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then( user => {
      var arg = "a=&b=&c="+user.username+"&d="+user.attributes.name+"&e="+user.attributes.phone_number+"&f="+user.attributes.email ;
      var url= config.owl_callback.starter+"?"+arg ; // awsin.mics
      //console.log (url) ;
      window.open(url,
        "レセプトチェッカーＬＳ",
        'scrollbars=no,status=no,titlebar=no,toolbar=no,status=no,resizable=yes,menubar=no,location=no,width=1024, height=700'
      );

    }).catch( err => {
      console.log("catch( err => {");
      console.log(err);
      //alert ("ログインをお願いします。") ;
      props.history.push("/login");
     });

  }

  return (
    <div className="Home">
      <div className="lander">
        <h1>クラウド版レセプト点検サービス</h1>
          <LoaderButton block onClick={handleClick} bsSize="large" >
             Ｓｔａｒｔ
          </LoaderButton>

          <NotificationComponent /> {/* NotificationComponent 사용 */}
          
{/* -- 2024.07.05~07.10 까지        
        <div class="wrap">
          <div class="box">
            <div class="in">
            <h4><b>ご使用のお客様へ<br></br></b></h4>
            <h4> </h4>
            <h4>この度の診療報酬改定に伴い、いくつかのプログラム追加・</h4>       
            <h4>修正を行いました。</h4>     
            <h4>そのため、すでに点検済のレセプトデータについて、</h4>
            <h4 class="noti_background">点検業務 ＞ 受付及び点検 ＞<font color="#fb0101">連続処理</font> を行ってください。</h4>           
            <h4>お手数をおかけしますが、どうぞよろしくお願いいたします。</h4>
            </div>
          </div>
        </div>  
*/}
{/* -- 2024.08.05 적용 */ }          
{/*--2024.08.19 적용 해제 
          <div class="wrap">
          <div class="box">
            <div class="in">
            <h4><b>サポートデスク夏季休業期間について</b></h4>
            <h4>平素より、レセプトチェッカーLSクラウド版を<br/>ご愛顧いただき誠にありがとうございます。</h4>
            <h4>サポートデスクの夏季休業期間をお知らせします。</h4>
            <h4 class="noti_color">2024年8月10日（土）～8月18日（日）<br/></h4>

            <h4>8月19日（月）より通常営業いたします。<br/>
            メールやレセプトチェッカーの問合せ機能からの問合せの回答は19日(月)から対応いたします。<br/><br/>
            何卒よろしくお願いいたします。<br/>
            レセプトチェッカーサポートデスク</h4>
            </div>
          </div>
        </div>
  */} 
      </div>
    </div>
  );
}
